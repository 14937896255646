import type { ILaboratoryOpenHours } from '~/modules/core/types'

interface IAddressFilter {
  address: string
  district: string
  number: string
  city: string
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      filters: {
        address: ({ address, district, number, city }: IAddressFilter) => {
          if (address && district && number && city)
            return `${address}, ${number} - ${district}, ${city}`

          if (address && district && number)
            return `${address}, ${number} - ${district}`

          return '--'
        },

        removeDiatrics: (str: string) =>
          str.normalize('NFD').replace(/[\u0300-\u036F]/g, ''),

        postalCode: (text: string = '') =>
          text ? `${text.substring(0, 5)}-${text.substring(5)}` : '-',

        currency: (
          value: any,
          locale = 'pt-BR',
          options = {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          },
        ) => {
          if (!value)
            return ''

          if (typeof value === 'string')
            value = Number.parseFloat(value)

          return value.toLocaleString(locale, options)
        },

        formatDateEnToBr: (datestring: string = '') => {
          if (typeof datestring !== 'string')
            return ''

          return datestring ? datestring.split('-').reverse().join('/') : ''
        },

        date: (value: Date | string) => {
          try {
            if (typeof value === 'string')
              value = new Date(value)

            if (value && value instanceof Date)
              return value.toLocaleDateString('pt-BR')
          }
          catch {
            return value
          }
        },

        formatDateBrToEn: (datestring: string = '') =>
          datestring ? datestring.split('/').reverse().join('-') : '',

        formatDatestringToDateWrittenInFull: (datestring: string) => {
          if (!datestring)
            return ''

          const date = new Date(datestring)

          return date
            .toLocaleString('pt-BR', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })
            .split(' de ')
            .join(' ')
        },

        time: (value: Date | string) => {
          try {
            if (typeof value === 'string')
              value = new Date(value)

            if (value && value instanceof Date)
              return value.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })

            return value
          }
          catch {
            return value
          }
        },

        formatTaxId: (str: string = '') => {
          return str
            ? str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
            : ''
        },

        turnBold: (text: string = '', part: string | Ref<string> = '') => {
          if (typeof part !== 'string' || !part.length)
            part = part.value

          const normalizedText = text
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .toLocaleLowerCase()

          const normalizedPart = part
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .toLocaleLowerCase()

          const index = normalizedText.indexOf(normalizedPart)

          if (index !== -1) {
            const highlighted = text.substring(index, index + part.length)

            return text.replace(highlighted, `<strong>${highlighted}</strong>`)
          }

          return text
        },

        zipCode: (zip: string = '') => {
          return zip && zip.length === 8
            ? zip.replace(/(\d{5})(\d{3})/, '$1-$2')
            : ''
        },

        phone: (phone = '') => {
          const unmaskedPhoneLength = 13

          const maskedPhonePattern = /^\+\d{2} \(\d{2}\) \d{1}\d{4}-\d{4}$/

          if (maskedPhonePattern.test(phone)) {
            return phone.replace(/[^\d]/g, '')
          }
          else if (phone.length === unmaskedPhoneLength) {
            return phone.replace(
              /(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
              '+$1 ($2) $3$4-$5',
            )
          }

          return ''
        },

        openingHours: (openingHours: ILaboratoryOpenHours): string[] => {
          if (!openingHours)
            return []

          const dayNames: any = {
            monday: 'Seg',
            tuesday: 'Ter',
            wednesday: 'Qua',
            thursday: 'Qui',
            friday: 'Sex',
            saturday: 'Sab',
            sunday: 'Dom',
          }

          const sortedOpeningHours: any = {}

          for (const day in dayNames) {
            const newDayName = dayNames[day]
            sortedOpeningHours[newDayName] = openingHours[day]
          }

          const hoursToDaysMap: any = {}

          for (const [day, hours] of Object.entries(sortedOpeningHours)) {
            if (!hours.length)
              continue

            const hoursString = JSON.stringify(hours)

            if (!hoursToDaysMap[hoursString])
              hoursToDaysMap[hoursString] = []

            hoursToDaysMap[hoursString].push(day)
          }

          const result = Object.keys(hoursToDaysMap)
            .map((key) => {
              const initialDay = hoursToDaysMap[key][0]
              let lastDay = ''
              if (hoursToDaysMap[key].length > 1)
                lastDay = ` - ${hoursToDaysMap[key][hoursToDaysMap[key].length - 1]}`

              const [start, end] = Object.values(JSON.parse(key)[0])

              return `${initialDay}${lastDay}: ${start} às ${end}`
            })
            .join(' | ')

          return result
        },
      },
    },
  }
})
